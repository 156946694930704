@import '~bootstrap/dist/css/bootstrap.min.css';
@import '~react-perfect-scrollbar/dist/css/styles.css';
@import '~c3/c3.min.css';
@import '~react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

@import 'components/kit/vendors/antd/style';
@import 'components/kit/vendors/bootstrap/style';
@import 'components/kit/vendors/perfect-scrollbar/style';
@import 'components/kit/vendors/nprogress/style';
@import 'components/kit/vendors/chartist/style';
@import 'components/kit/vendors/chartist-tooltip-plugin/style';
@import 'components/kit/vendors/jvectormap/style';
@import 'components/kit/vendors/react-sortable-tree/style';
@import 'components/kit/vendors/react-draft-wysiwyg/style';

// Clean UI KIT styles
@import 'components/kit/core/style';
// Clean UI styles
@import 'components/cleanui/styles/style';
// Custom App styles
@import 'components/app/style/custom';

// [data-kit-theme='default'] .ant-form-item {
//   margin-bottom: 12px !important;
// }

[data-kit-theme="default"] .ant-menu-item-selected a, [data-kit-theme="default"] .ant-menu-item-selected a:hover {
    color: #ffffff;
}

.theme-btn-style
{
    background-color: #000;
    color: #fff;
}

.theme-btn-style:hover
{
    background-color: #ffcd1f;
    color: #000;
    font-weight: 500;
}

.style_menu__2t_Y4 .style_icon__1H4q6
{
    color: #000 !important;
}